<!-- Toolbar -->
<div class="background">
  <svg class="grid" width="94vw" height="48vw" viewBox="0 0 1200 600" xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <pattern id = "patAA" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/AA.png" />
        </pattern>
        <pattern id = "patGA" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/GA.png" />
        </pattern>
        <pattern id = "patLA" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/LA.png" />
        </pattern>
        <pattern id = "patTA" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/TA.png" />
        </pattern>
        <pattern id = "patAL" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/AL.png" />
        </pattern>
        <pattern id = "patPL" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/PL.png" />
        </pattern>
        <pattern id = "patGL" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/GL.png" />
        </pattern>
        <pattern id = "patYL" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/YL.png" />
        </pattern>
        <pattern id = "patRL" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/RL.png" />
        </pattern>
        <pattern id = "patTL" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/TL.png" />
        </pattern>
        <pattern id = "patAM" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/AM.png" />
        </pattern>
        <pattern id = "patPM" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/PM.png" />
        </pattern>
        <pattern id = "patGM" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/GM.png" />
        </pattern>
        <pattern id = "patYM" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/YM.png" />
        </pattern>
        <pattern id = "patRM" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/RM.png" />
        </pattern>
        <pattern id = "patTM" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/TM.png" />
        </pattern>
        <pattern id = "patGB" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/GB.png" />
        </pattern>
        <pattern id = "patIB" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/IB.png" />
        </pattern>
        <pattern id = "patANB" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/ANB.png" />
        </pattern>
        <pattern id = "patTCB" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/TCB.png" />
        </pattern>
        <pattern id = "patTLB" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/TLB.png" />
        </pattern>
        <pattern id = "patChest" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/chest.png" />
        </pattern>
        <pattern id = "patNoPath" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/nopath.png" />
        </pattern>
        <pattern id = "patPath" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/path.png" />
        </pattern>
        <pattern id = "infoIcon" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/info.png" />
        </pattern>
        <pattern id = "coinIcon" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
        <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/coin.png" />
        </pattern>
        <pattern id = "FFA_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_02.png" />
          </pattern>
          <pattern id = "Glucose_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_01.png" />
          </pattern>
          <pattern id = "Glycerol_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_07.png" />
          </pattern>
          <pattern id = "TAG_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_03.png" />
          </pattern>
          <pattern id = "G6P_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_04.png" />
          </pattern>
          <pattern id = "Glycogen_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_06.png" />
          </pattern>
          <pattern id = "Pyruvate_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_05.png" />
          </pattern>
          <pattern id = "Insulin_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_11.png" />
          </pattern>
          <pattern id = "NEFA_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_08.png" />
          </pattern>
          <pattern id = "CM_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_10.png" />
          </pattern>
          <pattern id = "VLDL_B" height = "100%" width = "100%" patternContentUnits = "objectBoundingBox">
          <image height = "1" width = "1" preserveAspectRatio = "none" xlink:href = "assets/barrels_09.png" />
          </pattern>
      </defs>
      <polygon id="hex0" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[0].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="24.3902,0 48.7805,14.0976 48.7805,42.2927 24.3902,56.3902 0,42.2927 0,14.0976" />
      <polygon id="hex1" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[1].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="73.1707,0 97.561,14.0976 97.561,42.2927 73.1707,56.3902 48.7805,42.2927 48.7805,14.0976" />
      <polygon id="hex2" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[2].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="121.951,0 146.341,14.0976 146.341,42.2927 121.951,56.3902 97.561,42.2927 97.561,14.0976" />
      <polygon id="hex3" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[3].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="170.732,0 195.122,14.0976 195.122,42.2927 170.732,56.3902 146.341,42.2927 146.341,14.0976" />
      <polygon id="hex4" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[4].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="219.512,0 243.902,14.0976 243.902,42.2927 219.512,56.3902 195.122,42.2927 195.122,14.0976" />
      <polygon id="hex5" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[5].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="268.293,0 292.683,14.0976 292.683,42.2927 268.293,56.3902 243.902,42.2927 243.902,14.0976" />
      <polygon id="hex6" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[6].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="317.073,0 341.463,14.0976 341.463,42.2927 317.073,56.3902 292.683,42.2927 292.683,14.0976" />
      <polygon id="hex7" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[7].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="365.854,0 390.244,14.0976 390.244,42.2927 365.854,56.3902 341.463,42.2927 341.463,14.0976" />
      <polygon id="hex8" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[8].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="414.634,0 439.024,14.0976 439.024,42.2927 414.634,56.3902 390.244,42.2927 390.244,14.0976" />
      <polygon id="hex9" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[9].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="463.415,0 487.805,14.0976 487.805,42.2927 463.415,56.3902 439.024,42.2927 439.024,14.0976" />
      <polygon id="hex10" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[10].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="512.195,0 536.585,14.0976 536.585,42.2927 512.195,56.3902 487.805,42.2927 487.805,14.0976" />
      <polygon id="hex11" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[11].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="560.976,0 585.366,14.0976 585.366,42.2927 560.976,56.3902 536.585,42.2927 536.585,14.0976" />
      <polygon id="hex12" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[12].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="609.756,0 634.146,14.0976 634.146,42.2927 609.756,56.3902 585.366,42.2927 585.366,14.0976" />
      <polygon id="hex13" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[13].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="658.537,0 682.927,14.0976 682.927,42.2927 658.537,56.3902 634.146,42.2927 634.146,14.0976" />
      <polygon id="hex14" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[14].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="707.317,0 731.707,14.0976 731.707,42.2927 707.317,56.3902 682.927,42.2927 682.927,14.0976" />
      <polygon id="hex15" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[15].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="756.098,0 780.488,14.0976 780.488,42.2927 756.098,56.3902 731.707,42.2927 731.707,14.0976" />
      <polygon id="hex16" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[16].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="804.878,0 829.268,14.0976 829.268,42.2927 804.878,56.3902 780.488,42.2927 780.488,14.0976" />
      <polygon id="hex17" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[17].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="853.659,0 878.049,14.0976 878.049,42.2927 853.659,56.3902 829.268,42.2927 829.268,14.0976" />
      <polygon id="hex18" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[18].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="902.439,0 926.829,14.0976 926.829,42.2927 902.439,56.3902 878.049,42.2927 878.049,14.0976" />
      <polygon id="hex19" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[19].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="951.219,0 975.61,14.0976 975.61,42.2927 951.219,56.3902 926.829,42.2927 926.829,14.0976" />
      <polygon id="hex20" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[20].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="48.7805,42.2927 73.1707,56.3902 73.1707,84.5854 48.7805,98.6829 24.3902,84.5854 24.3902,56.3902" />
      <polygon id="hex21" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[21].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="97.561,42.2927 121.951,56.3902 121.951,84.5854 97.561,98.6829 73.1707,84.5854 73.1707,56.3902" />
      <polygon id="hex22" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[22].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="146.341,42.2927 170.732,56.3902 170.732,84.5854 146.341,98.6829 121.951,84.5854 121.951,56.3902" />
      <polygon id="hex23" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[23].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="195.122,42.2927 219.512,56.3902 219.512,84.5854 195.122,98.6829 170.732,84.5854 170.732,56.3902" />
      <polygon id="hex24" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[24].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="243.902,42.2927 268.293,56.3902 268.293,84.5854 243.902,98.6829 219.512,84.5854 219.512,56.3902" />
      <polygon id="hex25" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[25].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="292.683,42.2927 317.073,56.3902 317.073,84.5854 292.683,98.6829 268.293,84.5854 268.293,56.3902" />
      <polygon id="hex26" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[26].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="341.463,42.2927 365.854,56.3902 365.854,84.5854 341.463,98.6829 317.073,84.5854 317.073,56.3902" />
      <polygon id="hex27" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[27].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="390.244,42.2927 414.634,56.3902 414.634,84.5854 390.244,98.6829 365.854,84.5854 365.854,56.3902" />
      <polygon id="hex28" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[28].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="439.024,42.2927 463.415,56.3902 463.415,84.5854 439.024,98.6829 414.634,84.5854 414.634,56.3902" />
      <polygon id="hex29" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[29].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="487.805,42.2927 512.195,56.3902 512.195,84.5854 487.805,98.6829 463.415,84.5854 463.415,56.3902" />
      <polygon id="hex30" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[30].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="536.585,42.2927 560.976,56.3902 560.976,84.5854 536.585,98.6829 512.195,84.5854 512.195,56.3902" />
      <polygon id="hex31" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[31].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="585.366,42.2927 609.756,56.3902 609.756,84.5854 585.366,98.6829 560.976,84.5854 560.976,56.3902" />
      <polygon id="hex32" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[32].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="634.146,42.2927 658.537,56.3902 658.537,84.5854 634.146,98.6829 609.756,84.5854 609.756,56.3902" />
      <polygon id="hex33" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[33].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="682.927,42.2927 707.317,56.3902 707.317,84.5854 682.927,98.6829 658.537,84.5854 658.537,56.3902" />
      <polygon id="hex34" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[34].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="731.707,42.2927 756.098,56.3902 756.098,84.5854 731.707,98.6829 707.317,84.5854 707.317,56.3902" />
      <polygon id="hex35" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[35].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="780.488,42.2927 804.878,56.3902 804.878,84.5854 780.488,98.6829 756.098,84.5854 756.098,56.3902" />
      <polygon id="hex36" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[36].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="829.268,42.2927 853.659,56.3902 853.659,84.5854 829.268,98.6829 804.878,84.5854 804.878,56.3902" />
      <polygon id="hex37" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[37].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="878.049,42.2927 902.439,56.3902 902.439,84.5854 878.049,98.6829 853.659,84.5854 853.659,56.3902" />
      <polygon id="hex38" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[38].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="926.829,42.2927 951.219,56.3902 951.219,84.5854 926.829,98.6829 902.439,84.5854 902.439,56.3902" />
      <polygon id="hex39" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[39].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="975.61,42.2927 1000,56.3902 1000,84.5854 975.61,98.6829 951.219,84.5854 951.219,56.3902" />
      <polygon id="hex40" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[40].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="24.3902,84.5854 48.7805,98.6829 48.7805,126.878 24.3902,140.976 0,126.878 0,98.6829" />
      <polygon id="hex41" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[41].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="73.1707,84.5854 97.561,98.6829 97.561,126.878 73.1707,140.976 48.7805,126.878 48.7805,98.6829" />
      <polygon id="hex42" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[42].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="121.951,84.5854 146.341,98.6829 146.341,126.878 121.951,140.976 97.561,126.878 97.561,98.6829" />
      <polygon id="hex43" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[43].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="170.732,84.5854 195.122,98.6829 195.122,126.878 170.732,140.976 146.341,126.878 146.341,98.6829" />
      <polygon id="hex44" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[44].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="219.512,84.5854 243.902,98.6829 243.902,126.878 219.512,140.976 195.122,126.878 195.122,98.6829" />
      <polygon id="hex45" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[45].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="268.293,84.5854 292.683,98.6829 292.683,126.878 268.293,140.976 243.902,126.878 243.902,98.6829" />
      <polygon id="hex46" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[46].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="317.073,84.5854 341.463,98.6829 341.463,126.878 317.073,140.976 292.683,126.878 292.683,98.6829" />
      <polygon id="hex47" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[47].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="365.854,84.5854 390.244,98.6829 390.244,126.878 365.854,140.976 341.463,126.878 341.463,98.6829" />
      <polygon id="hex48" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[48].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="414.634,84.5854 439.024,98.6829 439.024,126.878 414.634,140.976 390.244,126.878 390.244,98.6829" />
      <polygon id="hex49" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[49].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="463.415,84.5854 487.805,98.6829 487.805,126.878 463.415,140.976 439.024,126.878 439.024,98.6829" />
      <polygon id="hex50" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[50].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="512.195,84.5854 536.585,98.6829 536.585,126.878 512.195,140.976 487.805,126.878 487.805,98.6829" />
      <polygon id="hex51" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[51].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="560.976,84.5854 585.366,98.6829 585.366,126.878 560.976,140.976 536.585,126.878 536.585,98.6829" />
      <polygon id="hex52" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[52].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="609.756,84.5854 634.146,98.6829 634.146,126.878 609.756,140.976 585.366,126.878 585.366,98.6829" />
      <polygon id="hex53" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[53].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="658.537,84.5854 682.927,98.6829 682.927,126.878 658.537,140.976 634.146,126.878 634.146,98.6829" />
      <polygon id="hex54" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[54].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="707.317,84.5854 731.707,98.6829 731.707,126.878 707.317,140.976 682.927,126.878 682.927,98.6829" />
      <polygon id="hex55" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[55].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="756.098,84.5854 780.488,98.6829 780.488,126.878 756.098,140.976 731.707,126.878 731.707,98.6829" />
      <polygon id="hex56" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[56].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="804.878,84.5854 829.268,98.6829 829.268,126.878 804.878,140.976 780.488,126.878 780.488,98.6829" />
      <polygon id="hex57" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[57].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="853.659,84.5854 878.049,98.6829 878.049,126.878 853.659,140.976 829.268,126.878 829.268,98.6829" />
      <polygon id="hex58" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[58].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="902.439,84.5854 926.829,98.6829 926.829,126.878 902.439,140.976 878.049,126.878 878.049,98.6829" />
      <polygon id="hex59" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[59].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="951.219,84.5854 975.61,98.6829 975.61,126.878 951.219,140.976 926.829,126.878 926.829,98.6829" />
      <polygon id="hex60" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[60].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="48.7805,126.878 73.1707,140.976 73.1707,169.171 48.7805,183.268 24.3902,169.171 24.3902,140.976" />
      <polygon id="hex61" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[61].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="97.561,126.878 121.951,140.976 121.951,169.171 97.561,183.268 73.1707,169.171 73.1707,140.976" />
      <polygon id="hex62" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[62].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="146.341,126.878 170.732,140.976 170.732,169.171 146.341,183.268 121.951,169.171 121.951,140.976" />
      <polygon id="hex63" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[63].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="195.122,126.878 219.512,140.976 219.512,169.171 195.122,183.268 170.732,169.171 170.732,140.976" />
      <polygon id="hex64" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[64].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="243.902,126.878 268.293,140.976 268.293,169.171 243.902,183.268 219.512,169.171 219.512,140.976" />
      <polygon id="hex65" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[65].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="292.683,126.878 317.073,140.976 317.073,169.171 292.683,183.268 268.293,169.171 268.293,140.976" />
      <polygon id="hex66" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[66].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="341.463,126.878 365.854,140.976 365.854,169.171 341.463,183.268 317.073,169.171 317.073,140.976" />
      <polygon id="hex67" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[67].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="390.244,126.878 414.634,140.976 414.634,169.171 390.244,183.268 365.854,169.171 365.854,140.976" />
      <polygon id="hex68" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[68].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="439.024,126.878 463.415,140.976 463.415,169.171 439.024,183.268 414.634,169.171 414.634,140.976" />
      <polygon id="hex69" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[69].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="487.805,126.878 512.195,140.976 512.195,169.171 487.805,183.268 463.415,169.171 463.415,140.976" />
      <polygon id="hex70" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[70].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="536.585,126.878 560.976,140.976 560.976,169.171 536.585,183.268 512.195,169.171 512.195,140.976" />
      <polygon id="hex71" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[71].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="585.366,126.878 609.756,140.976 609.756,169.171 585.366,183.268 560.976,169.171 560.976,140.976" />
      <polygon id="hex72" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[72].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="634.146,126.878 658.537,140.976 658.537,169.171 634.146,183.268 609.756,169.171 609.756,140.976" />
      <polygon id="hex73" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[73].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="682.927,126.878 707.317,140.976 707.317,169.171 682.927,183.268 658.537,169.171 658.537,140.976" />
      <polygon id="hex74" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[74].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="731.707,126.878 756.098,140.976 756.098,169.171 731.707,183.268 707.317,169.171 707.317,140.976" />
      <polygon id="hex75" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[75].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="780.488,126.878 804.878,140.976 804.878,169.171 780.488,183.268 756.098,169.171 756.098,140.976" />
      <polygon id="hex76" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[76].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="829.268,126.878 853.659,140.976 853.659,169.171 829.268,183.268 804.878,169.171 804.878,140.976" />
      <polygon id="hex77" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[77].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="878.049,126.878 902.439,140.976 902.439,169.171 878.049,183.268 853.659,169.171 853.659,140.976" />
      <polygon id="hex78" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[78].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="926.829,126.878 951.219,140.976 951.219,169.171 926.829,183.268 902.439,169.171 902.439,140.976" />
      <polygon id="hex79" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[79].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="975.61,126.878 1000,140.976 1000,169.171 975.61,183.268 951.219,169.171 951.219,140.976" />
      <polygon id="hex80" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[80].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="24.3902,169.171 48.7805,183.268 48.7805,211.463 24.3902,225.561 0,211.463 0,183.268" />
      <polygon id="hex81" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[81].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="73.1707,169.171 97.561,183.268 97.561,211.463 73.1707,225.561 48.7805,211.463 48.7805,183.268" />
      <polygon id="hex82" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[82].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="121.951,169.171 146.341,183.268 146.341,211.463 121.951,225.561 97.561,211.463 97.561,183.268" />
      <polygon id="hex83" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[83].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="170.732,169.171 195.122,183.268 195.122,211.463 170.732,225.561 146.341,211.463 146.341,183.268" />
      <polygon id="hex84" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[84].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="219.512,169.171 243.902,183.268 243.902,211.463 219.512,225.561 195.122,211.463 195.122,183.268" />
      <polygon id="hex85" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[85].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="268.293,169.171 292.683,183.268 292.683,211.463 268.293,225.561 243.902,211.463 243.902,183.268" />
      <polygon id="hex86" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[86].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="317.073,169.171 341.463,183.268 341.463,211.463 317.073,225.561 292.683,211.463 292.683,183.268" />
      <polygon id="hex87" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[87].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="365.854,169.171 390.244,183.268 390.244,211.463 365.854,225.561 341.463,211.463 341.463,183.268" />
      <polygon id="hex88" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[88].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="414.634,169.171 439.024,183.268 439.024,211.463 414.634,225.561 390.244,211.463 390.244,183.268" />
      <polygon id="hex89" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[89].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="463.415,169.171 487.805,183.268 487.805,211.463 463.415,225.561 439.024,211.463 439.024,183.268" />
      <polygon id="hex90" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[90].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="512.195,169.171 536.585,183.268 536.585,211.463 512.195,225.561 487.805,211.463 487.805,183.268" />
      <polygon id="hex91" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[91].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="560.976,169.171 585.366,183.268 585.366,211.463 560.976,225.561 536.585,211.463 536.585,183.268" />
      <polygon id="hex92" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[92].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="609.756,169.171 634.146,183.268 634.146,211.463 609.756,225.561 585.366,211.463 585.366,183.268" />
      <polygon id="hex93" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[93].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="658.537,169.171 682.927,183.268 682.927,211.463 658.537,225.561 634.146,211.463 634.146,183.268" />
      <polygon id="hex94" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[94].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="707.317,169.171 731.707,183.268 731.707,211.463 707.317,225.561 682.927,211.463 682.927,183.268" />
      <polygon id="hex95" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[95].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="756.098,169.171 780.488,183.268 780.488,211.463 756.098,225.561 731.707,211.463 731.707,183.268" />
      <polygon id="hex96" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[96].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="804.878,169.171 829.268,183.268 829.268,211.463 804.878,225.561 780.488,211.463 780.488,183.268" />
      <polygon id="hex97" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[97].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="853.659,169.171 878.049,183.268 878.049,211.463 853.659,225.561 829.268,211.463 829.268,183.268" />
      <polygon id="hex98" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[98].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="902.439,169.171 926.829,183.268 926.829,211.463 902.439,225.561 878.049,211.463 878.049,183.268" />
      <polygon id="hex99" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[99].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="951.219,169.171 975.61,183.268 975.61,211.463 951.219,225.561 926.829,211.463 926.829,183.268" />
      <polygon id="hex100" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[100].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="48.7805,211.463 73.1707,225.561 73.1707,253.756 48.7805,267.854 24.3902,253.756 24.3902,225.561" />
      <polygon id="hex101" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[101].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="97.561,211.463 121.951,225.561 121.951,253.756 97.561,267.854 73.1707,253.756 73.1707,225.561" />
      <polygon id="hex102" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[102].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="146.341,211.463 170.732,225.561 170.732,253.756 146.341,267.854 121.951,253.756 121.951,225.561" />
      <polygon id="hex103" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[103].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="195.122,211.463 219.512,225.561 219.512,253.756 195.122,267.854 170.732,253.756 170.732,225.561" />
      <polygon id="hex104" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[104].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="243.902,211.463 268.293,225.561 268.293,253.756 243.902,267.854 219.512,253.756 219.512,225.561" />
      <polygon id="hex105" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[105].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="292.683,211.463 317.073,225.561 317.073,253.756 292.683,267.854 268.293,253.756 268.293,225.561" />
      <polygon id="hex106" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[106].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="341.463,211.463 365.854,225.561 365.854,253.756 341.463,267.854 317.073,253.756 317.073,225.561" />
      <polygon id="hex107" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[107].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="390.244,211.463 414.634,225.561 414.634,253.756 390.244,267.854 365.854,253.756 365.854,225.561" />
      <polygon id="hex108" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[108].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="439.024,211.463 463.415,225.561 463.415,253.756 439.024,267.854 414.634,253.756 414.634,225.561" />
      <polygon id="hex109" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[109].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="487.805,211.463 512.195,225.561 512.195,253.756 487.805,267.854 463.415,253.756 463.415,225.561" />
      <polygon id="hex110" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[110].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="536.585,211.463 560.976,225.561 560.976,253.756 536.585,267.854 512.195,253.756 512.195,225.561" />
      <polygon id="hex111" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[111].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="585.366,211.463 609.756,225.561 609.756,253.756 585.366,267.854 560.976,253.756 560.976,225.561" />
      <polygon id="hex112" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[112].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="634.146,211.463 658.537,225.561 658.537,253.756 634.146,267.854 609.756,253.756 609.756,225.561" />
      <polygon id="hex113" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[113].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="682.927,211.463 707.317,225.561 707.317,253.756 682.927,267.854 658.537,253.756 658.537,225.561" />
      <polygon id="hex114" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[114].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="731.707,211.463 756.098,225.561 756.098,253.756 731.707,267.854 707.317,253.756 707.317,225.561" />
      <polygon id="hex115" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[115].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="780.488,211.463 804.878,225.561 804.878,253.756 780.488,267.854 756.098,253.756 756.098,225.561" />
      <polygon id="hex116" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[116].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="829.268,211.463 853.659,225.561 853.659,253.756 829.268,267.854 804.878,253.756 804.878,225.561" />
      <polygon id="hex117" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[117].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="878.049,211.463 902.439,225.561 902.439,253.756 878.049,267.854 853.659,253.756 853.659,225.561" />
      <polygon id="hex118" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[118].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="926.829,211.463 951.219,225.561 951.219,253.756 926.829,267.854 902.439,253.756 902.439,225.561" />
      <polygon id="hex119" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[119].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="975.61,211.463 1000,225.561 1000,253.756 975.61,267.854 951.219,253.756 951.219,225.561" />
      <polygon id="hex120" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[120].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="24.3902,253.756 48.7805,267.854 48.7805,296.049 24.3902,310.146 0,296.049 0,267.854" />
      <polygon id="hex121" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[121].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="73.1707,253.756 97.561,267.854 97.561,296.049 73.1707,310.146 48.7805,296.049 48.7805,267.854" />
      <polygon id="hex122" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[122].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="121.951,253.756 146.341,267.854 146.341,296.049 121.951,310.146 97.561,296.049 97.561,267.854" />
      <polygon id="hex123" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[123].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="170.732,253.756 195.122,267.854 195.122,296.049 170.732,310.146 146.341,296.049 146.341,267.854" />
      <polygon id="hex124" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[124].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="219.512,253.756 243.902,267.854 243.902,296.049 219.512,310.146 195.122,296.049 195.122,267.854" />
      <polygon id="hex125" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[125].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="268.293,253.756 292.683,267.854 292.683,296.049 268.293,310.146 243.902,296.049 243.902,267.854" />
      <polygon id="hex126" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[126].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="317.073,253.756 341.463,267.854 341.463,296.049 317.073,310.146 292.683,296.049 292.683,267.854" />
      <polygon id="hex127" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[127].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="365.854,253.756 390.244,267.854 390.244,296.049 365.854,310.146 341.463,296.049 341.463,267.854" />
      <polygon id="hex128" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[128].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="414.634,253.756 439.024,267.854 439.024,296.049 414.634,310.146 390.244,296.049 390.244,267.854" />
      <polygon id="hex129" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[129].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="463.415,253.756 487.805,267.854 487.805,296.049 463.415,310.146 439.024,296.049 439.024,267.854" />
      <polygon id="hex130" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[130].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="512.195,253.756 536.585,267.854 536.585,296.049 512.195,310.146 487.805,296.049 487.805,267.854" />
      <polygon id="hex131" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[131].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="560.976,253.756 585.366,267.854 585.366,296.049 560.976,310.146 536.585,296.049 536.585,267.854" />
      <polygon id="hex132" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[132].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="609.756,253.756 634.146,267.854 634.146,296.049 609.756,310.146 585.366,296.049 585.366,267.854" />
      <polygon id="hex133" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[133].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="658.537,253.756 682.927,267.854 682.927,296.049 658.537,310.146 634.146,296.049 634.146,267.854" />
      <polygon id="hex134" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[134].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="707.317,253.756 731.707,267.854 731.707,296.049 707.317,310.146 682.927,296.049 682.927,267.854" />
      <polygon id="hex135" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[135].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="756.098,253.756 780.488,267.854 780.488,296.049 756.098,310.146 731.707,296.049 731.707,267.854" />
      <polygon id="hex136" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[136].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="804.878,253.756 829.268,267.854 829.268,296.049 804.878,310.146 780.488,296.049 780.488,267.854" />
      <polygon id="hex137" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[137].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="853.659,253.756 878.049,267.854 878.049,296.049 853.659,310.146 829.268,296.049 829.268,267.854" />
      <polygon id="hex138" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[138].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="902.439,253.756 926.829,267.854 926.829,296.049 902.439,310.146 878.049,296.049 878.049,267.854" />
      <polygon id="hex139" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[139].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="951.219,253.756 975.61,267.854 975.61,296.049 951.219,310.146 926.829,296.049 926.829,267.854" />
      <polygon id="hex140" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[140].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="48.7805,296.049 73.1707,310.146 73.1707,338.341 48.7805,352.439 24.3902,338.341 24.3902,310.146" />
      <polygon id="hex141" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[141].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="97.561,296.049 121.951,310.146 121.951,338.341 97.561,352.439 73.1707,338.341 73.1707,310.146" />
      <polygon id="hex142" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[142].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="146.341,296.049 170.732,310.146 170.732,338.341 146.341,352.439 121.951,338.341 121.951,310.146" />
      <polygon id="hex143" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[143].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="195.122,296.049 219.512,310.146 219.512,338.341 195.122,352.439 170.732,338.341 170.732,310.146" />
      <polygon id="hex144" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[144].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="243.902,296.049 268.293,310.146 268.293,338.341 243.902,352.439 219.512,338.341 219.512,310.146" />
      <polygon id="hex145" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[145].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="292.683,296.049 317.073,310.146 317.073,338.341 292.683,352.439 268.293,338.341 268.293,310.146" />
      <polygon id="hex146" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[146].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="341.463,296.049 365.854,310.146 365.854,338.341 341.463,352.439 317.073,338.341 317.073,310.146" />
      <polygon id="hex147" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[147].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="390.244,296.049 414.634,310.146 414.634,338.341 390.244,352.439 365.854,338.341 365.854,310.146" />
      <polygon id="hex148" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[148].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="439.024,296.049 463.415,310.146 463.415,338.341 439.024,352.439 414.634,338.341 414.634,310.146" />
      <polygon id="hex149" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[149].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="487.805,296.049 512.195,310.146 512.195,338.341 487.805,352.439 463.415,338.341 463.415,310.146" />
      <polygon id="hex150" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[150].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="536.585,296.049 560.976,310.146 560.976,338.341 536.585,352.439 512.195,338.341 512.195,310.146" />
      <polygon id="hex151" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[151].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="585.366,296.049 609.756,310.146 609.756,338.341 585.366,352.439 560.976,338.341 560.976,310.146" />
      <polygon id="hex152" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[152].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="634.146,296.049 658.537,310.146 658.537,338.341 634.146,352.439 609.756,338.341 609.756,310.146" />
      <polygon id="hex153" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[153].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="682.927,296.049 707.317,310.146 707.317,338.341 682.927,352.439 658.537,338.341 658.537,310.146" />
      <polygon id="hex154" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[154].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="731.707,296.049 756.098,310.146 756.098,338.341 731.707,352.439 707.317,338.341 707.317,310.146" />
      <polygon id="hex155" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[155].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="780.488,296.049 804.878,310.146 804.878,338.341 780.488,352.439 756.098,338.341 756.098,310.146" />
      <polygon id="hex156" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[156].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="829.268,296.049 853.659,310.146 853.659,338.341 829.268,352.439 804.878,338.341 804.878,310.146" />
      <polygon id="hex157" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[157].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="878.049,296.049 902.439,310.146 902.439,338.341 878.049,352.439 853.659,338.341 853.659,310.146" />
      <polygon id="hex158" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[158].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="926.829,296.049 951.219,310.146 951.219,338.341 926.829,352.439 902.439,338.341 902.439,310.146" />
      <polygon id="hex159" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[159].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="975.61,296.049 1000,310.146 1000,338.341 975.61,352.439 951.219,338.341 951.219,310.146" />
      <polygon id="hex160" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[160].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="24.3902,338.341 48.7805,352.439 48.7805,380.634 24.3902,394.732 0,380.634 0,352.439" />
      <polygon id="hex161" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[161].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="73.1707,338.341 97.561,352.439 97.561,380.634 73.1707,394.732 48.7805,380.634 48.7805,352.439" />
      <polygon id="hex162" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[162].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="121.951,338.341 146.341,352.439 146.341,380.634 121.951,394.732 97.561,380.634 97.561,352.439" />
      <polygon id="hex163" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[163].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="170.732,338.341 195.122,352.439 195.122,380.634 170.732,394.732 146.341,380.634 146.341,352.439" />
      <polygon id="hex164" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[164].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="219.512,338.341 243.902,352.439 243.902,380.634 219.512,394.732 195.122,380.634 195.122,352.439" />
      <polygon id="hex165" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[165].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="268.293,338.341 292.683,352.439 292.683,380.634 268.293,394.732 243.902,380.634 243.902,352.439" />
      <polygon id="hex166" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[166].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="317.073,338.341 341.463,352.439 341.463,380.634 317.073,394.732 292.683,380.634 292.683,352.439" />
      <polygon id="hex167" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[167].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="365.854,338.341 390.244,352.439 390.244,380.634 365.854,394.732 341.463,380.634 341.463,352.439" />
      <polygon id="hex168" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[168].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="414.634,338.341 439.024,352.439 439.024,380.634 414.634,394.732 390.244,380.634 390.244,352.439" />
      <polygon id="hex169" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[169].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="463.415,338.341 487.805,352.439 487.805,380.634 463.415,394.732 439.024,380.634 439.024,352.439" />
      <polygon id="hex170" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[170].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="512.195,338.341 536.585,352.439 536.585,380.634 512.195,394.732 487.805,380.634 487.805,352.439" />
      <polygon id="hex171" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[171].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="560.976,338.341 585.366,352.439 585.366,380.634 560.976,394.732 536.585,380.634 536.585,352.439" />
      <polygon id="hex172" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[172].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="609.756,338.341 634.146,352.439 634.146,380.634 609.756,394.732 585.366,380.634 585.366,352.439" />
      <polygon id="hex173" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[173].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="658.537,338.341 682.927,352.439 682.927,380.634 658.537,394.732 634.146,380.634 634.146,352.439" />
      <polygon id="hex174" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[174].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="707.317,338.341 731.707,352.439 731.707,380.634 707.317,394.732 682.927,380.634 682.927,352.439" />
      <polygon id="hex175" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[175].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="756.098,338.341 780.488,352.439 780.488,380.634 756.098,394.732 731.707,380.634 731.707,352.439" />
      <polygon id="hex176" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[176].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="804.878,338.341 829.268,352.439 829.268,380.634 804.878,394.732 780.488,380.634 780.488,352.439" />
      <polygon id="hex177" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[177].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="853.659,338.341 878.049,352.439 878.049,380.634 853.659,394.732 829.268,380.634 829.268,352.439" />
      <polygon id="hex178" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[178].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="902.439,338.341 926.829,352.439 926.829,380.634 902.439,394.732 878.049,380.634 878.049,352.439" />
      <polygon id="hex179" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[179].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="951.219,338.341 975.61,352.439 975.61,380.634 951.219,394.732 926.829,380.634 926.829,352.439" />
      <polygon id="hex180" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[180].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="48.7805,380.634 73.1707,394.732 73.1707,422.927 48.7805,437.024 24.3902,422.927 24.3902,394.732" />
      <polygon id="hex181" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[181].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="97.561,380.634 121.951,394.732 121.951,422.927 97.561,437.024 73.1707,422.927 73.1707,394.732" />
      <polygon id="hex182" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[182].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="146.341,380.634 170.732,394.732 170.732,422.927 146.341,437.024 121.951,422.927 121.951,394.732" />
      <polygon id="hex183" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[183].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="195.122,380.634 219.512,394.732 219.512,422.927 195.122,437.024 170.732,422.927 170.732,394.732" />
      <polygon id="hex184" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[184].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="243.902,380.634 268.293,394.732 268.293,422.927 243.902,437.024 219.512,422.927 219.512,394.732" />
      <polygon id="hex185" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[185].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="292.683,380.634 317.073,394.732 317.073,422.927 292.683,437.024 268.293,422.927 268.293,394.732" />
      <polygon id="hex186" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[186].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="341.463,380.634 365.854,394.732 365.854,422.927 341.463,437.024 317.073,422.927 317.073,394.732" />
      <polygon id="hex187" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[187].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="390.244,380.634 414.634,394.732 414.634,422.927 390.244,437.024 365.854,422.927 365.854,394.732" />
      <polygon id="hex188" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[188].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="439.024,380.634 463.415,394.732 463.415,422.927 439.024,437.024 414.634,422.927 414.634,394.732" />
      <polygon id="hex189" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[189].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="487.805,380.634 512.195,394.732 512.195,422.927 487.805,437.024 463.415,422.927 463.415,394.732" />
      <polygon id="hex190" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[190].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="536.585,380.634 560.976,394.732 560.976,422.927 536.585,437.024 512.195,422.927 512.195,394.732" />
      <polygon id="hex191" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[191].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="585.366,380.634 609.756,394.732 609.756,422.927 585.366,437.024 560.976,422.927 560.976,394.732" />
      <polygon id="hex192" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[192].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="634.146,380.634 658.537,394.732 658.537,422.927 634.146,437.024 609.756,422.927 609.756,394.732" />
      <polygon id="hex193" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[193].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="682.927,380.634 707.317,394.732 707.317,422.927 682.927,437.024 658.537,422.927 658.537,394.732" />
      <polygon id="hex194" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[194].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="731.707,380.634 756.098,394.732 756.098,422.927 731.707,437.024 707.317,422.927 707.317,394.732" />
      <polygon id="hex195" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[195].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="780.488,380.634 804.878,394.732 804.878,422.927 780.488,437.024 756.098,422.927 756.098,394.732" />
      <polygon id="hex196" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[196].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="829.268,380.634 853.659,394.732 853.659,422.927 829.268,437.024 804.878,422.927 804.878,394.732" />
      <polygon id="hex197" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[197].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="878.049,380.634 902.439,394.732 902.439,422.927 878.049,437.024 853.659,422.927 853.659,394.732" />
      <polygon id="hex198" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[198].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="926.829,380.634 951.219,394.732 951.219,422.927 926.829,437.024 902.439,422.927 902.439,394.732" />
      <polygon id="hex199" onclick="hex_click(evt)" onmouseover="hex_hover(evt)" onmouseout="hex_unhover(evt)" [attr.fill]="hexFill[199].show" stroke="navajowhite" stroke-width="2" pointer-events="visible" points="975.61,380.634 1000,394.732 1000,422.927 975.61,437.024 951.219,422.927 951.219,394.732" />
      <g id="infoGroup" onclick="info_click(evt)">
      <rect id="infoRect" fill="url(#infoIcon)" stroke="none" stroke-width="1" pointer-events="visible" x="20" y="440" width="96" height="152" />
      <text id="infoText" x="20" y="580" font-family="Finger Paint" font-size="16" fill="navajowhite">Information</text>
      </g>
      <g id="pointGroup" onclick="point_click(evt)">
      <rect id="pointRect" fill="navajowhite" fill-opacity="0.25" stroke="saddlebrown" stroke-width="2" pointer-events="visible" x="1050" y="20" width="80" height="30" />
      <text id="pointText1" x="1070" y="40" font-family="Finger Paint" font-size="20" fill="black" [(textContent)] = "points"></text>
      <text id="pointText2" x="1055" y="70" font-family="Finger Paint" font-size="20" fill="saddlebrown">Points</text>
      </g>
      <g id="pathLenGroup">
      <rect id="pathLenRect" fill="navajowhite" fill-opacity="0.25" stroke="saddlebrown" stroke-width="2" pointer-events="visible" x="1050" y="80" width="80" height="30" />
      <text id="pathLenText1" x="1078" y="100" font-family="Finger Paint" font-size="20" fill="black" [(textContent)] = "pathCount"></text>
      <text id="pathLenText2" x="1015" y="130" font-family="Finger Paint" font-size="20" fill="saddlebrown">Paths Remaining</text>
      </g>
      <g id="boostGroup" onclick="boost_click(evt)">
      <rect id="boostRect0" fill="url(#coinIcon)" stroke="none" stroke-width="1" pointer-events="visible" x="1014" y="140" width="40" height="40" />
      <text id="boostText0" x="1014" y="195" font-family="Finger Paint" font-size="16" fill="black">{{boosts[0].name}}</text>
      <rect id="boostRect1" fill="url(#coinIcon)" stroke="none" stroke-width="1" pointer-events="visible" x="1080" y="140" width="40" height="40" />
      <text id="boostText1" x="1080" y="195" font-family="Finger Paint" font-size="16" fill="black">{{boosts[1].name}}</text>
      <rect id="boostRect2" fill="url(#coinIcon)" stroke="none" stroke-width="1" pointer-events="visible" x="1146" y="140" width="40" height="40" />
      <text id="boostText2" x="1146" y="195" font-family="Finger Paint" font-size="16" fill="black">{{boosts[2].name}}</text>
      </g>
      <g id="LiverGroup">
        <rect id="LiverRect" fill="navajowhite" fill-opacity="0.5" stroke="#6633ff" stroke-width="2" pointer-events="visible" [attr.x]="150" [attr.y]="530" width="455" height="73" />
        <rect id="LBRect1" fill="url(#Glucose_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="182.5" [attr.y]="530" width="50" height="62" />
        <text id="LBCnt1" x="185" y="555" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="LBText1" x="190" y="583" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">Glucose</text>
        <rect id="LBRect2" fill="url(#FFA_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="247.5" [attr.y]="530" width="50" height="62" />
        <text id="LBCnt2" x="250" y="555" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="LBText2" x="266" y="583" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">FFA</text>
        <rect id="LBRect3" fill="url(#TAG_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="312.5" [attr.y]="530" width="50" height="62" />
        <text id="LBCnt3" x="315" y="555" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="LBText3" x="330" y="583" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">TAG</text>
        <rect id="LBRect4" fill="url(#G6P_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="377.5" [attr.y]="530" width="50" height="62" />
        <text id="LBCnt4" x="380" y="555" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="LBText4" x="393" y="583" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">G-6-P</text>
        <rect id="LBRect5" fill="url(#Pyruvate_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="442.5" [attr.y]="530" width="50" height="62" />
        <text id="LBCnt5" x="445" y="555" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="LBText5" x="449" y="583" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">Pyruvate</text>
        <rect id="LBRect6" fill="url(#Glycogen_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="507.5" [attr.y]="530" width="50" height="62" />
        <text id="LBCnt6" x="510" y="555" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="LBText6" x="514" y="583" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">Glycogen</text>
        <text id="LiverText" [attr.x]="361" [attr.y]="600" font-family="Finger Paint" font-size="12" fill="#6633ff">Liver</text>
        </g>
        <g id="MuscleGroup">
        <rect id="MuscleRect" fill="navajowhite" fill-opacity="0.5" stroke="#00ff00" stroke-width="2" pointer-events="visible" [attr.x]="150" [attr.y]="445" width="455" height="73" />
        <rect id="MBRect1" fill="url(#Glucose_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="182.5" [attr.y]="445" width="50" height="62" />
        <text id="MBCnt1" x="185" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="MBText1" x="190" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">Glucose</text>
        <rect id="MBRect2" fill="url(#FFA_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="247.5" [attr.y]="445" width="50" height="62" />
        <text id="MBCnt2" x="250" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold">7</text>
        <text id="MBText2" x="266" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">FFA</text>
        <rect id="MBRect3" fill="url(#TAG_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="312.5" [attr.y]="445" width="50" height="62" />
        <text id="MBCnt3" x="315" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="MBText3" x="330" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">TAG</text>
        <rect id="MBRect4" fill="url(#G6P_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="377.5" [attr.y]="445" width="50" height="62" />
        <text id="MBCnt4" x="380" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="MBText4" x="393" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">G-6-P</text>
        <rect id="MBRect5" fill="url(#Pyruvate_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="442.5" [attr.y]="445" width="50" height="62" />
        <text id="MBCnt5" x="445" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="MBText5" x="449" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">Pyruvate</text>
        <rect id="MBRect6" fill="url(#Glycogen_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="507.5" [attr.y]="445" width="50" height="62" />
        <text id="MBCnt6" x="510" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="MBText6" x="514" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">Glycogen</text>
        <text id="MuscleText" [attr.x]="355" [attr.y]="515" font-family="Finger Paint" font-size="12" fill="#00ff00">Muscle</text>
        </g>
        <g id="PlasmaGroup">
        <rect id="PlasmaRect" fill="navajowhite" fill-opacity="0.5" stroke="red" stroke-width="2" pointer-events="visible" [attr.x]="610" [attr.y]="445" width="390" height="73" />
        <rect id="PBRect1" fill="url(#Glucose_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="642.5" [attr.y]="445" width="50" height="62" />
        <text id="PBCnt1" x="645" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="PBText1" x="651" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">Glucose</text>
        <rect id="PBRect2" fill="url(#NEFA_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="707.5" [attr.y]="445" width="50" height="62" />
        <text id="PBCnt2" x="710" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="PBText2" x="724" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">NEFA</text>
        <rect id="PBRect3" fill="url(#VLDL_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="772.5" [attr.y]="445" width="50" height="62" />
        <text id="PBCnt3" x="775" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="PBText3" x="789" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">VLDL</text>
        <rect id="PBRect4" fill="url(#CM_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="837.5" [attr.y]="445" width="50" height="62" />
        <text id="PBCnt4" x="840" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="PBText4" x="857" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">CM</text>
        <rect id="PBRect5" fill="url(#Insulin_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="902.5" [attr.y]="445" width="50" height="62" />
        <text id="PBCnt5" x="905" y="465" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
        <text id="PBText5" x="914" y="498" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">Insulin</text>
        <text id="PlasmaText" [attr.x]="780" [attr.y]="515" font-family="Finger Paint" font-size="12" fill="red">Plasma</text>
        </g>
        <g id="AdiposeGroup">
          <rect id="AdiposeRect" fill="navajowhite" fill-opacity="0.5" stroke="brown" stroke-width="2" pointer-events="visible"
            [attr.x]="643" [attr.y]="530" width="325" height="73" />
          <rect id="ABRect1" fill="url(#Glucose_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="675" [attr.y]="530" width="50" height="62" />
        <text id="ABCnt1" x="677.5" y="555" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
          <text id="ABText1" x="684" y="583" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">Glucose</text>
          <rect id="ABRect2" fill="url(#FFA_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="740" [attr.y]="530" width="50" height="62" />
        <text id="ABCnt2" x="742.5" y="555" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
          <text id="ABText2" x="758" y="583" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">FFA</text>
          <rect id="ABRect3" fill="url(#TAG_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="805" [attr.y]="530" width="50" height="62" />
        <text id="ABCnt3" x="807.5" y="555" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
          <text id="ABText3" x="822" y="583" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">TAG</text>
          <rect id="ABRect4" fill="url(#Glycerol_B)" fill-opacity="0.3" stroke="none" stroke-width="1" pointer-events="visible" [attr.x]="870" [attr.y]="530" width="50" height="62" />
        <text id="ABCnt4" x="872.5" y="555" font-family="Finger Paint, Courier" font-size="18" fill="gold"></text>
          <text id="ABText4" x="878" y="583" font-family="Finger Paint, Courier" font-size="8" fill="navajowhite">Glycerol</text>
          <text id="AdiposeText" [attr.x]="779" [attr.y]="600" font-family="Finger Paint" font-size="12" fill="brown">Adipose</text>
        </g>
  </svg>

</div>




<router-outlet></router-outlet>

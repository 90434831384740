import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {
  Component,
  OnInit
}
  from '@angular/core';

@Component(
  {
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
  }
)
export class AppComponent {
  // class variables
  title = 'alphagame1';
  points = 0;
  // possible gamestate values: [idle:0, selected1: 1, boost: 2, gameover: 3]
  gamestate = 0;
  tempGamestate = 0;
  treasureLost = false;
  isSticky = false;
  sonarActive = false;
  peekActive = false;
  pathfinderTime = 0;
  inBoostHover = false;
  delay = 1000;
  hexFill = [
    { show: "", path: 0, content: 0, state: "", index: 0 }
  ];
  firstHex;
  firstHexIndex = 0;
  Contents = [
    { name: "Nothing Here", show: "none", type: 0 },
    { name: "Adipose FFA", show: "url(#patAA)", type: 1, onHand: 0, transparency: .3 },
    { name: "Adipose Glucose", show: "url(#patGA)", type: 1, onHand: 0, transparency: .3 },
    { name: "Adipose Glycerol", show: "url(#patLA)", type: 1, onHand: 0, transparency: .3 },
    { name: "Adipose TAG", show: "url(#patTA)", type: 1, onHand: 0, transparency: .3 },
    { name: "Liver FFA", show: "url(#patAL)", type: 1, onHand: 0, transparency: .3 },
    { name: "Liver G-6-P", show: "url(#patPL)", type: 1, onHand: 0, transparency: .3 },
    { name: "Liver Glucose", show: "url(#patGL)", type: 1, onHand: 0, transparency: .3 },
    { name: "Liver Glycogen", show: "url(#patYL)", type: 1, onHand: 0, transparency: .3 },
    { name: "Liver Pyruvate", show: "url(#patRL)", type: 1, onHand: 0, transparency: .3 },
    { name: "Liver TAG", show: "url(#patTL)", type: 1, onHand: 0, transparency: .3 },
    { name: "Muscle FFA", show: "url(#patAM)", type: 1, onHand: 0, transparency: .3 },
    { name: "Muscle G-6-P", show: "url(#patPM)", type: 1, onHand: 0, transparency: .3 },
    { name: "Muscle Glucose", show: "url(#patGM)", type: 1, onHand: 0, transparency: .3 },
    { name: "Muscle Glycogen", show: "url(#patYM)", type: 1, onHand: 0, transparency: .3 },
    { name: "Muscle Pyruvate", show: "url(#patRM)", type: 1, onHand: 0, transparency: .3 },
    { name: "Muscle TAG", show: "url(#patTM)", type: 1, onHand: 0, transparency: .3 },
    { name: "Plasma Glucose", show: "url(#patGB)", type: 1, onHand: 0, transparency: .3 },
    { name: "Plasma Insulin", show: "url(#patIB)", type: 1, onHand: 0, transparency: .3 },
    { name: "Plasma NEFA", show: "url(#patANB)", type: 1, onHand: 0, transparency: .3 },
    { name: "Plasma CM TAG", show: "url(#patTCB)", type: 1, onHand: 0, transparency: .3 },
    { name: "Plasma VLDL", show: "url(#patTLB)", type: 1, onHand: 0, transparency: .3 },
    { name: "Chest0", show: "url(#patChest)", type: 2 },
    { name: "Chest1", show: "url(#patChest)", type: 2 },
    { name: "Chest2", show: "url(#patChest)", type: 2 },
    { name: "Chest3", show: "url(#patChest)", type: 2 },
    { name: "Chest4", show: "url(#patChest)", type: 2 },
    { name: "Chest5", show: "url(#patChest)", type: 2 },
    { name: "Chest6", show: "url(#patChest)", type: 2 },
    { name: "Chest7", show: "url(#patChest)", type: 2 },
    { name: "Chest8", show: "url(#patChest)", type: 2 },
    { name: "Chest9", show: "url(#patChest)", type: 2 },
    { name: "Chest10", show: "url(#patChest)", type: 3 },
    { name: "Chest11", show: "url(#patChest)", type: 3 },
    { name: "Chest12", show: "url(#patChest)", type: 3 },
    { name: "Chest13", show: "url(#patChest)", type: 3 },
    { name: "Chest14", show: "url(#patChest)", type: 3 }
  ]
  barrelUpdate = [
    { rect: "LBRect1", count: "LBCnt1", contentIndex: 7 },
    { rect: "LBRect2", count: "LBCnt2", contentIndex: 5 },
    { rect: "LBRect3", count: "LBCnt3", contentIndex: 10 },
    { rect: "LBRect4", count: "LBCnt4", contentIndex: 6 },
    { rect: "LBRect5", count: "LBCnt5", contentIndex: 9 },
    { rect: "LBRect6", count: "LBCnt6", contentIndex: 8 },
    { rect: "MBRect1", count: "MBCnt1", contentIndex: 13 },
    { rect: "MBRect2", count: "MBCnt2", contentIndex: 11 },
    { rect: "MBRect3", count: "MBCnt3", contentIndex: 16 },
    { rect: "MBRect4", count: "MBCnt4", contentIndex: 12 },
    { rect: "MBRect5", count: "MBCnt5", contentIndex: 15 },
    { rect: "MBRect6", count: "MBCnt6", contentIndex: 14 },
    { rect: "PBRect1", count: "PBCnt1", contentIndex: 17 },
    { rect: "PBRect2", count: "PBCnt2", contentIndex: 19 },
    { rect: "PBRect3", count: "PBCnt3", contentIndex: 21 },
    { rect: "PBRect4", count: "PBCnt4", contentIndex: 20 },
    { rect: "PBRect5", count: "PBCnt5", contentIndex: 18 },
    { rect: "ABRect1", count: "ABCnt1", contentIndex: 2 },
    { rect: "ABRect2", count: "ABCnt2", contentIndex: 1 },
    { rect: "ABRect3", count: "ABCnt3", contentIndex: 4 },
    { rect: "ABRect4", count: "ABCnt4", contentIndex: 3 }
  ]
  chestContents = [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3];
  Treasures = [{ index: 0, prob: 10, type: "Sonar" }, { index: 1, prob: 20, type: "Peek" }, { index: 2, prob: 70, type: "Points" }];
  treasurePoints = [{ prob: 50, points: 5 }, { prob: 25, points: 10 }, { prob: 13, points: 15 }, { prob: 7, points: 25 }, { prob: 5, points: 50 }];
  Hazards = [{ index: 0, prob: 5, type: "Swap4" }, { index: 1, prob: 10, type: "Swap2" }, { index: 2, prob: 15, type: "TLost" }, { index: 3, prob: 70, type: "PLost" }];
  hazardDamage = [{ prob: 50, percentage: .02 }, { prob: 25, percentage: .05 }, { prob: 13, percentage: .10 }, { prob: 7, percentage: .15 }, { prob: 5, percentage: .25 }];
  pathImage = ["url(#patNoPath)", "url(#patPath)"];
  hexRowCount = 10;
  hexColCount = 20;
  hexCount = this.hexRowCount * this.hexColCount;
  pathCount = 0;
  boosts = [{ name: 'Sonar', count: 2 }, { name: 'Peek', count: 3 }, { name: 'Sticky', count: 1 }];

  // class functions
  hex_hover(evt) {
    if (this.pathfinderTime > 0) {
      let hex = evt.target;
      hex.setAttribute("fill", this.pathImage[this.hexFill[evt.target.id.substring(3)].path]);
      hex.setAttribute("fill-opacity", 0.95);
      console.log(evt.target);
      return;
    }
  }

  hex_unhover(evt) {
    if (this.pathfinderTime > 0) {
      let hex = evt.target;
      hex.setAttribute("fill", this.hexFill[evt.target.id.substring(3)].show);
      hex.setAttribute("fill-opacity", 1);
      console.log(this.hexFill[evt.target.id.substring(3)]);
      console.log(evt.target);
      return;
    }
  }
  // click on hex (state sensitive response)
  hex_click(evt) {
    // alert(`Gamestate is ${this.gamestate}`) // DEBUG
    // create simple variable substitutes.
    let hex = evt.target;
    let hexIndex = hex.id.substring(3).valueOf();
    let hexObj = this.hexFill[hexIndex];
    let hexState = hexObj.state;
    if (hexState === "matched") return; // Don't process clicks on hexes that have been matched
    let hexPath = hexObj.path;
    let hexContent = hexObj.content;
    // Show content of hex
    this.hexFill[hexIndex].show = this.Contents[this.hexFill[hexIndex].content].show;
    hex.setAttribute("fill", this.hexFill[hexIndex].show);
    // BEGIN DELETE
    // this.hexFill[hexIndex].show = this.Contents[this.hexFill[evt.target.id.substring(3)].content].show;
    // hex.setAttribute("fill", this.hexFill[evt.target.id.substring(3)].show);
    // hex.setAttribute("fill-opacity", 1);
    // alert(hexContent);
    // END DELETE
    switch (this.gamestate) {
      // If *game-state* is **idle**
      case 0:
        // if hex *state* is **matched** or **selected**
        if (hexState === 'selected') return; // click is ignored (do nothing)
        // if hex *content* is treasure chest ("c0-c9")
        if (this.Contents[hexContent].type === 2) {
          this.treasure_found(hex, hexContent, hexIndex, hexPath);
          return; // return
        }

        // if hex content is hazard chest (c10-c14)
        if (this.Contents[hexContent].type === 3) {
          this.hazard_found(hex, hexContent, hexIndex, hexPath);
          return;
        }
        // if hex is jewel
        if (this.Contents[hexContent].type === 1) {
          this.firstHex = hex;  // set *firstHex* to this hex
          this.firstHexIndex = hexIndex; // store the index to hexFill object
          this.hexFill[hexIndex].state = 'selected'; // set hex *state* to **selected**
          this.hexFill[hexIndex].show = this.Contents[hexContent].show; // set hex *show* to hex *content*
          hex.setAttribute('stroke', 'brown');
          this.gamestate = 1;// set game *state* to **selected1**
          return; // return
        }
        // if hex is empty
        if (this.Contents[hexContent].type === 0) {
          this.hexFill[hexIndex].state = 'matched'; // set hex state to **matched**
          this.hexFill[hexIndex].show = this.pathImage[hexPath]; // set hex *show* to mapImage[hex *path*] (it will be a beach image  )
          hex.setAttribute("fill", this.pathImage[hexPath]);
          return; // return
        }
        break;
      // if *game-state* is **selected1**
      case 1:
        if (hexState === 'selected') return; // click is ignored (do nothing)
        // if hex is treasure
        if (this.Contents[hexContent].type === 2) {
          this.treasure_found(hex, hexContent, hexIndex, hexPath);
          return;
        }
        // if hex is hazard
        if (this.Contents[hexContent].type === 3) {
          this.hazard_found(hex, hexContent, hexIndex, hexPath);
          return;
        }
        // if hex is jewel or empty
        if (this.Contents[hexContent].type < 2) {
          this.points--;
          this.update_points();
          this.gamestate = 0;
          let firstHexObj = this.hexFill[this.firstHexIndex];
          if (firstHexObj.content === hexContent) { // if *firstHex* *content* is equal to hex *content*
            firstHexObj.state = "matched";
            hexObj.state = "matched";
            this.Contents[hexContent].onHand += 2;// add 2 to appropriate substance container.
            this.Contents[hexContent].transparency = 1;
            alert(`Two ${this.Contents[hexContent].name} have been saved to a barrel.`);
            setTimeout(() => {
              // show beach or path for each hex
              firstHexObj.show = this.pathImage[firstHexObj.path];
              hexObj.show = this.pathImage[hexPath];
              this.firstHex.setAttribute("fill", firstHexObj.show);
              this.firstHex.setAttribute("stroke", 'navajowhite');
              hex.setAttribute("fill", hexObj.show);
            }, this.delay)

            // check if *path* is "1" for either hex and decrease *pathCount* for each path found
            if (hexPath === 1) this.pathCount--;
            if (firstHexObj.path === 1) this.pathCount--;
            this.update_path_count();
            this.update_SVG_Barrels();
          } else {  // if *firstHex* *content* not equal to hex *content*
            firstHexObj.state = "clicked";
            hexObj.state = "clicked";
            // alert(`Not a match, matey.`);
            if (!this.isSticky) { // if *is_sticky* is false, replace *show* for both hexes
              setTimeout(() => {
                firstHexObj.show = "none";
                this.firstHex.setAttribute("fill", "none");
                hexObj.show = "none";
                hex.setAttribute("fill", "none");
              }, this.delay)

            }
            else {
              if (this.Contents[hexContent].type === 0) {
                this.hexFill[hexIndex].show = this.pathImage[hexPath];
                hex.setAttribute("fill", this.pathImage[hexPath]);
                this.hexFill[hexIndex].state = "matched";
              }
            }
          }
          this.firstHex.setAttribute("stroke", "navajowhite");
        }
        break;
      // if *game-state* is **boost**
      case 2:
        console.log(`Entering Case 2: sonarActive is ${this.sonarActive}`);
        if (this.sonarActive) { // if boost is *sonar*
          let offsets = [{ even: -1, odd: 0, row: -1 }, { even: 0, odd: 1, row: -1 }, { even: 1, odd: 1, row: 0 }, { even: 0, odd: 1, row: 1 }, { even: -1, odd: 0, row: 1 }, { even: -1, odd: -1, row: 0 }];
          let sonarHexArray: number[] = [];
          sonarHexArray.push(hexIndex);
          let centerRow = Math.floor(hexIndex / 20);
          let centerCol = hexIndex % 20;
          let odd = centerRow % 2 === 1;
          let pingCol = -1;
          let pingRow = -1;
          console.log(`entering loop. odd is ${odd} hex is ${hexIndex} centerCol is ${centerCol} and centerRow is ${centerRow}`);
          for (let ping of offsets) {
            if (odd) {
              pingCol = ping.odd + centerCol;
            } else {
              pingCol = ping.even + centerCol;
            }
            pingRow = ping.row + centerRow;
            if (pingCol >= 0 && pingCol < 20 && pingRow >= 0 && pingRow < 10) sonarHexArray.push(pingRow * 20 + pingCol);
            console.log(`pingCol is ${pingCol}, pingRow is ${pingRow}, odd is ${odd} and array is ${sonarHexArray}`);
          }
          this.displayHexWithDelay(sonarHexArray, 3000);
          console.log(`exiting loop`);
          this.gamestate = this.tempGamestate;
          this.sonarActive = false;
          if (!this.isSticky) hex.setAttribute("fill", "none");
        }
        break;
      default:
        break;
    }
    if (this.gamestate === 3) {
      this.game_over();
      window.location.reload();
    }
  }

  info_click(evt) {
    alert("Ahoy, matey! Find the hidden path by matching jewels before you run out of points.");
    console.log(evt.target);
  }

  point_click(evt) {
    alert(`Ya have ${this.points} shiny silver coins, matey.`);
    console.log(evt.target);
  }

  boost_click(evt) {
    if (this.gamestate == 2) {
      alert(`Ye need to use the active boost before spending another coin, matey.`);
      return;
    }
    switch (parseInt(evt.target.id.substring(9), 10)) {
      case 0: // Sonar
        if (this.boosts[evt.target.id.substring(9)].count > 0) {
          var r = confirm(`Are ye sure you want to spend a Sonar coin, matey? This will leave ye with ${this.boosts[0].count - 1} Sonar coin.`);
          if (r == true) {
            this.boosts[evt.target.id.substring(9)].count--;
            this.sonarActive = true;
            this.tempGamestate = this.gamestate;
            this.gamestate = 2;
          }
        }
        else {
          alert(`Yer outta those coins, matey.`);
        }
        break;
      case 1: // Peek
        if (this.boosts[1].count > 0) {
          var r = confirm("Are ye sure you want to spend a Peek coin, matey?");
          if (r == true) {
            this.boosts[1].count--;
            let rNum1 = Math.floor(Math.random() * this.hexCount);
            let rNum2 = Math.floor(Math.random() * this.hexCount);
            console.log(`rNum1 is ${rNum1} rNum2 is ${rNum2}`);
            rNum1 = this.find_hidden_hex(rNum1);
            if (rNum1 < 0) {
              alert(`Nothing to peek at. rNum1 is ${rNum1}`);
              this.boosts[1].count++;
              break;
            }
            rNum2 = this.find_hidden_hex(rNum2);
            if (rNum2 < 0) {
              alert(`Nothing to peek at. rNum2 is ${rNum2}`);
              this.boosts[1].count++;
              break;
            }
            this.displayHexWithDelay([rNum1, rNum2], 3000);
          }
          alert(`You have ${this.boosts[1].count} Peek coins left, matey.`)
        }
        else {
          alert(`Ye don't have any o' those coins, matey.`);
        }
        break;
      case 2: // Sticky
        if (this.points < 70) {
          alert(`Ye needs to 'ave at least 70 points to spend a Sticky coin, matey`);
          return;
        }
        if (this.boosts[evt.target.id.substring(9)].count === 1) {
          var r = confirm("This will cost you 100 points, matey. Still want to use the Sticky coin?");
          if (r == true) {
            this.boosts[evt.target.id.substring(9)].count = 0;
            this.points -= 100;
            this.update_points();
            this.isSticky = true;
          }
        }
        break;
      default:
        console.log('ISSUE WITH BOOST CODE!!!'); // DEBUG
    }
  }

  // barrel_hover(evt) {
  //   console.log(`Hovering over ${evt.target.id}`);
  //   if (evt.target.id === "LBRect1") {
  //     document.getElementById(`LBCnt1`).textContent = this.Contents[7].onHand.toString();
  //     if (this.Contents[7].onHand > 0) {
  //       evt.target.setAttribute("fill-opacity", 1);
  //     }
  //   }
  // }

  // barrel_unhover(evt) {
  //   console.log(`Leaving ${evt.target.id}`);
  // }

  constructor() {
    (<any>window).hex_hover = this.hex_hover.bind(this);
    (<any>window).hex_unhover = this.hex_unhover.bind(this);
    (<any>window).hex_click = this.hex_click.bind(this);
    (<any>window).info_click = this.info_click.bind(this);
    (<any>window).point_click = this.point_click.bind(this);
    (<any>window).boost_click = this.boost_click.bind(this);
    // (<any>window).barrel_hover = this.barrel_hover.bind(this);
    // (<any>window).barrel_unhover = this.barrel_unhover.bind(this);

  }
  ngOnInit() {
    this.game_setup();
  }

  game_setup() {
    this.points += 200; // Add points for new game_setup
    // clear the hex board
    for (let i = 0; i < this.hexCount; i++) {
      this.hexFill[i] =
      {
        show: "none",
        path: 0,
        content: 0,
        state: "unclicked",
        index: i
      };
    }
    let hexsRemaining = this.hexFill.length - 1;

    // create jewel list
    let jewelList = [];
    // console.log("in game setup"); // DEBUG
    for (let index = 0; index < 21; index++) {
      for (let index4 = 0; index4 < 4; index4++) {
        jewelList.push(index + 1);
      }
    }
    let jewelsRemaining = jewelList.length - 1;
    let jewelValue = 0;

    // create and place the jewels on the path
    let path = { row: Math.floor(Math.random() * this.hexRowCount), col: 0, index: function () { return this.row * 20 + this.col } };
    // console.log(`Before loop pathIndex=${path.index()}`); // DEBUG
    let pathIncreased = true;
    while ((path.col) < 20) {
      if (pathIncreased) {
        this.pathCount++;
        this.hexFill[path.index()].path = 1;
        const jewelIndex = Math.floor(Math.random() * jewelsRemaining);
        jewelValue = jewelList[jewelIndex];
        jewelList[jewelIndex] = jewelList[jewelsRemaining--];
        jewelList.length = jewelsRemaining + 1;
        this.hexFill[path.index()].content = jewelValue;
        // DEBUG Start
        // console.log(`pathIndex=${path.index()} content is ${this.Contents[this.hexFill[path.index()].content].name} length is ${this.pathCount}`);
        // console.log(`jewel list ${jewelList}`);
        // DEBUG End
        // console.log(path); // DEBUG
        pathIncreased = false;
      }
      // Get next path hex
      switch (Math.floor(Math.random() * 3)) {
        case 0:
          if (path.row > 0) {
            path.col += path.row % 2;
            path.row--;
            pathIncreased = true;
          }
          break;
        case 1:
          path.col++;
          pathIncreased = true;
          break;
        case 2:
          if (path.row < 9) {
            path.col += path.row % 2;
            path.row++;
            pathIncreased = true;
          }
          break;
        default:
          console.log("Error in pathfill switch statement"); // DEBUG
          break;
      }

    }

    // create container of non-path map hexes
    let nonpathHexes = [];
    for (const [i, hex] of this.hexFill.entries()) {
      if (hex.path === 0) nonpathHexes.push(i);
      // console.log(`hex is ${i} path is ${hex.path}`); // DEBUG
    }
    // console.log(`total hexes are ${this.pathCount+nonpathHexes.length}`); // DEBUG
    let nonpathHexesRemaining = nonpathHexes.length - 1;
    for (const jewel of jewelList) {
      let hexIndex = Math.floor(Math.random() * nonpathHexesRemaining);
      this.hexFill[nonpathHexes[hexIndex]].content = jewel;
      nonpathHexes[hexIndex] = nonpathHexes[nonpathHexesRemaining--];
      nonpathHexes.length = nonpathHexesRemaining + 1;
      // console.log(`nonpathHexes: ${nonpathHexes}`); // DEBUG
    }
    for (let chestIndex = 22; chestIndex <= 36; chestIndex++) {
      let hexIndex = Math.floor(Math.random() * nonpathHexesRemaining);
      this.hexFill[nonpathHexes[hexIndex]].content = chestIndex;
      nonpathHexes[hexIndex] = nonpathHexes[nonpathHexesRemaining--];
      nonpathHexes.length = nonpathHexesRemaining + 1;
    }
    // DEBUG Start
    // let tempI = 0;
    // this.hexFill.forEach(hex => {
    //   hex.show = this.Contents[hex.content].show;
    // });
    // DEBUG End

    // Fill treasure chestContentss
    for (let chest = 0; chest < 10; chest++) {
      let Prob = 100;
      let content = 2;
      let probVal = Math.floor(Math.random() * Prob);
      while (content > -1) {
        Prob -= this.Treasures[content].prob;
        if (probVal >= Prob) {
          this.chestContents[chest] = content;
          content = -1;
        }
        content--;
      }
    }
    // Fill hazard chestContentss
    for (let chest = 10; chest < 15; chest++) {
      let Prob = 100;
      let content = 3;
      let probVal = Math.floor(Math.random() * Prob);
      while (content > -1) {
        Prob -= this.Hazards[content].prob;
        if (probVal >= Prob) {
          this.chestContents[chest] = content;
          content = -1;
        }
        content--;
      }
    }
    // console.log(`Chest content: ${this.chestContents}`); // DEBUG
    this.update_SVG_Barrels();
    return;
  }

  // ***SUPPORT FUNCTIONS*********************************
  treasure_found(theHex, theContent, theIndex, thePath) {
    if (this.treasureLost) {
      this.treasureLost = false;
      alert(`Remember when we spilt the grog on the treasure map? Well, we can't find the treasure 'cause we can't read the map!`);
    } else {
      switch (this.chestContents[this.Contents[theContent].name.substring(5)]) { // Process treasure
        case 0:
          alert('Great news, matey! Ye \'ave added a Sonar coin to your collection. PING!!!');
          this.boosts[0].count++;
          let rNum = Math.floor(Math.random() * 100);
          if (rNum > 50) {
            this.pathfinderTime = + rNum;
          }
          break;
        case 1:
          alert('Well, ruffle me parrot\'s feathers, matey, ye found a Peek coin.');
          this.boosts[1].count++;
          break;
        case 2:
          let foundIndex = -1;
          let Prob = 100;
          let pIndex = 4;
          let probVal = Math.floor(Math.random() * Prob);
          while (pIndex > -1) {
            Prob -= this.treasurePoints[pIndex].prob;
            if (probVal >= Prob) {
              this.points += this.treasurePoints[pIndex].points;
              this.update_points();
              foundIndex = pIndex;
              pIndex = -1;
            }
            pIndex--;
          }
          alert(`Shiver me timbers -- Ye found treasure! ${this.treasurePoints[foundIndex].points} glitterin' silver coins.`);
          break;
      }
    }
    this.hexFill[theIndex].show = this.pathImage[thePath]; // set hex *show* to mapImage[hex *path*] (always beach image)
    theHex.setAttribute("fill", this.hexFill[theIndex].show);
    this.hexFill[theIndex].state = 'matched'; // set hex *state* to **matched**
    return; // return
  }

  // Process a hazard
  hazard_found(theHex, theContent, theIndex, thePath) {
    switch (this.chestContents[this.Contents[theContent].name.substring(5)]) { // Process hazard
      case 0: // Swap4
        alert('Foul weather!!! Lash up to the mast, matey. Four items were scrambled.');
        this.swap_two();
        this.swap_two();
        break;
      case 1: // Swap2
        alert('Rogue wave off the starboard, matey. Two items were shifted.');
        this.swap_two();
        break;
      case 2: // TLost
        this.treasureLost = true;
        alert('Uh oh. I spilt grog on a treasure map.')
        break;
      case 3: // PLost
        let foundIndex = -1;
        let Prob = 100;
        let pIndex = 4;
        let probVal = Math.floor(Math.random() * Prob);
        while (pIndex > -1) {
          Prob -= this.hazardDamage[pIndex].prob;
          if (probVal >= Prob) {
            let lostCoins = Math.floor(this.hazardDamage[pIndex].percentage * this.points);
            this.points -= lostCoins;
            this.update_points();
            foundIndex = pIndex;
            pIndex = -1;
            alert(`Aargh!!! -- We've lost ${lostCoins} precious silver coins.`);
          }
          pIndex--;
        }
        break;
    }
    this.hexFill[theIndex].show = this.pathImage[thePath]; // set hex *show* to mapImage[hex *path*] (always beach  image)
    theHex.setAttribute("fill", this.hexFill[theIndex].show);
    this.hexFill[theIndex].state = 'matched'; // set hex *state* to **matched**
  }

  game_over() {
    alert(`Game over matey, but play another round to get more points.`);
    this.game_setup();
    return;
  }

  swap_two() {
    let rNum1 = Math.floor(Math.random() * this.hexCount);
    let rNum2 = Math.floor(Math.random() * this.hexCount);
    let hex1;
    rNum1 = this.find_hidden_hex(rNum1);
    if (rNum1 < 0) { return; }
    rNum2 = this.find_hidden_hex(rNum2);
    if (rNum2 < 0) { return; }
    alert(`Swapping: hex1 contains  ${this.hexFill[rNum1].content} and hex2 contains ${this.hexFill[rNum2].content}`);
    hex1 = this.hexFill[rNum1];
    this.hexFill[rNum1] = this.hexFill[rNum2];
    this.hexFill[rNum2] = hex1;
    alert(`Swapped: hex1 contains  ${this.hexFill[rNum1].content} and hex2 contains ${this.hexFill[rNum2].content}`);
    return;
  }

  find_hidden_hex(num) {
    let count = 0;
    while (count < this.hexCount) {
      console.log(`count is ${count}`);
      let candidate = this.hexFill[(num + count) % this.hexCount];
      if (candidate.path === 0 && candidate.show === 'none' && candidate.content > 0) {
        if (candidate.state != 'selected') {
          console.log(`candidate is ${candidate.content} hexCount is ${this.hexCount} count is ${count}`);
          return (num + count) % this.hexCount;
        }
      }
      count++;
    }
    console.log(`Didn't find hidden hex. count is ${count}`)
    return -1;
  }

  update_points() {
    document.getElementById('pointText1').textContent = this.points.toString();
    // console.log(`points are: ${this.points}`); // DEBUG
    if (this.points <= 0) {
      this.gamestate = 3;
      alert(`Ye ran out o' points, matey.`);
      return;
    }
  }


  update_path_count() {
    document.getElementById('pathLenText1').textContent = this.pathCount.toString();
    // if *pathCount* <= 0 the game has been completed.
    if (this.pathCount <= 0) {
      alert(`Drop anchor, matey! Ye have arrived safely home. For more points, sail away again with the outgoing tide.`);
      this.gamestate = 3;
    }
  }

  displayHexWithDelay(hexArray, delay) {
    console.log('In display Hex with Delay');
    let tempShow: string[] = Array.from({ length: hexArray.length });
    let showIndex = 0;
    for (const index of hexArray) {
      tempShow[showIndex] = this.hexFill[index].show;
      console.log(`hex is ${index} and content is ${this.Contents[this.hexFill[index].content].show}`);
      document.getElementById(`hex${index}`).setAttribute("fill", this.Contents[this.hexFill[index].content].show);
      showIndex++;
    }
    // console.log(`tempShow is ${tempShow}`); // DEBUG
    if (this.isSticky) return;
    setTimeout(() => {
      showIndex = 0;
      for (const index of hexArray) {
        // document.getElementById(`hex${index}`).setAttribute("fill",tempShow[showIndex]);
        this.set_SVG_HexFill(index, tempShow[showIndex]);
        showIndex++;
      }
    }, delay)
    return;
  }

  get_SVG_HexFill(index) {
    return document.getElementById(`hex${index}`).getAttribute("fill");
  }

  set_SVG_HexFill(index, fillString) {
    document.getElementById(`hex${index}`).setAttribute("fill", fillString);
    return;
  }

  update_SVG_Barrels() {
    // TODO
    // update the transparency and count values for each barrel
    for (const brl of this.barrelUpdate) {
      document.getElementById(brl.rect).setAttribute("fill-opacity", this.Contents[brl.contentIndex].transparency.toString());
      document.getElementById(brl.count).textContent = this.Contents[brl.contentIndex].onHand.toString();
    }
    return;
  }
}
